import React, { useRef } from 'react'
// custom hook
import { useAnimateOnScroll } from '../../Hooks/useAnimateOnScroll'
// image
import profileImg from '../../Images/profile_pic.jpg'

export default function About() {

  // refs
  const aboutHeaderRef = useRef();
  const elementRef = useRef();
  // custom hook
  const { addHeaderReveal, addElementReveal } = useAnimateOnScroll(aboutHeaderRef, elementRef);

  // animations
  const reveal = {
    animation: 'reveal .8s ease-in-out .2s forwards'
  }
  const leftReveal = {
    animation: 'leftReveal 1s ease-in-out forwards'
  }
  const rightReveal = {
    animation: 'rightReveal 1s ease-in-out forwards'
  }

  return <section className="about" id="about">
    <div className="container">
      <div className="about-elements">
        <div
          className="heading"
          ref={aboutHeaderRef}
          style={addHeaderReveal ? reveal : null}
        >
          <p>About me</p>
        </div>
        <div
          className="line"
          style={addHeaderReveal ? reveal : null}
        ></div>
        <div className="abt-content" ref={elementRef}>
          <div
            className="abt-left-part"
            style={addElementReveal ? leftReveal : null}
          >
            <img src={profileImg} alt="profile-pic" className="profile-pic" />
          </div>
          <div
            className="abt-right-part"
            style={addElementReveal ? rightReveal : null}
          >
            <p className="the-abt">I'm a front-end developer from India. I like building websites and passionate about learning new techs and upgrading my skills.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
}