import React from 'react'
import ReactDom from 'react-dom'
// style
import './Portal.css'

export default function Portal({ children, open }) {
    if (!open) return null
    return ReactDom.createPortal(
        <>
            <div className="overlay"></div>
            <div className="portalContainer" >{children}</div>
        </>,
        document.getElementById('myportal')
    )
}
