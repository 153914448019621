import React, { useRef } from 'react'
// custom hook
import { useAnimateOnScroll } from '../../Hooks/useAnimateOnScroll'
// components
import ProjectCard from './ProjectCard';
// images
import sunG from '../../Images/sunG.png'
import movierImg from '../../Images/movier.png'
// import wikiImg from '../../Images/Wiki.png'
import houseeImg from '../../Images/housee.png'
// sunG Images
import s1 from '../../Images/ProjImage/sunG/s1.png'
import s2 from '../../Images/ProjImage/sunG/s2.png'
import s3 from '../../Images/ProjImage/sunG/s3.png'
import s4 from '../../Images/ProjImage/sunG/s4.png'
import s5 from '../../Images/ProjImage/sunG/s5.png'
import s6 from '../../Images/ProjImage/sunG/s6.png'
import s7 from '../../Images/ProjImage/sunG/s7.png'
import s8 from '../../Images/ProjImage/sunG/s8.png'
// movier images
import m1 from '../../Images/ProjImage/movier/m1.png'
import m2 from '../../Images/ProjImage/movier/m2.png'
import m3 from '../../Images/ProjImage/movier/m3.png'
import m4 from '../../Images/ProjImage/movier/m4.png'
// housee images
import h1 from '../../Images/ProjImage/housee/h1.png'
import h2 from '../../Images/ProjImage/housee/h2.png'
import h3 from '../../Images/ProjImage/housee/h3.png'
import h4 from '../../Images/ProjImage/housee/h4.png'
import h5 from '../../Images/ProjImage/housee/h5.png'
import h6 from '../../Images/ProjImage/housee/h6.png'
import h7 from '../../Images/ProjImage/housee/h7.png'
import h8 from '../../Images/ProjImage/housee/h8.png'
// wiki images
// import w1 from '../../Images/ProjImage/wiki/w1.png'
// import w2 from '../../Images/ProjImage/wiki/w2.png'
// import w3 from '../../Images/ProjImage/wiki/w3.png'
// import w4 from '../../Images/ProjImage/wiki/w4.png'


export default function Projects() {

    const projectDetails = [
        {
            id: 'p1',
            projName: 'Sunglasses Ecommerce App',
            projImage: sunG,
            projDesc: 'An ecommerce app for sunglasses with lots of beautiful shades. We have Sunglasses of Brands like RayBan, Burberry, Maui JIm, Gucci.',
            tech: ['HTML', "CSS", 'JavaScript', 'React', 'NextJs', 'NodeJs', 'MongoDB'],
            projPics: [s1, s2, s3, s4, s5, s6, s7, s8],
            git: 'https://github.com/SatendraDiwakar/NextJs-Sunglasses-Ecommerce-App',
            demo: 'https://satendra-sunglass-ecommerce.vercel.app/'
        },
        {
            id: 'p2',
            projName: 'Movie Info-Ver',
            projImage: movierImg,
            projDesc: 'With Movie Info-Ver users can find information about their favorite movies and tv shows or they can also explore lots of movies and tv shows.',
            tech: ['HTML', "CSS", 'JavaScript', 'React'],
            projPics: [m1, m2, m3, m4,],
            git: 'https://github.com/SatendraDiwakar/movier',
            demo: 'https://movier.netlify.app/'
        },
        // {
        //     id: 'p3',
        //     projName: 'Wiki App',
        //     projImage: wikiImg,
        //     projDesc: 'An MVC app to create, read, update and delete database using RESTAPI.',
        //     tech: ['HTML', "CSS", 'JavaScript', 'RESTAPI', 'EJS', 'NodeJs', 'Express', 'MongoDB'],
        //     projPics: [w1, w2, w3, w4,],
        //     git: 'https://github.com/SatendraDiwakar/Wiki-APP_MVC_NodeJs_RESTAPI',
        //     demo: 'https://wiki-app-satendra.herokuapp.com/'
        // },
        {
            id: 'p4',
            projName: 'Housee',
            projImage: houseeImg,
            projDesc: 'Housee is a website that provides travelers world class Houses with world class services.',
            tech: ['HTML', "CSS", 'JavaScript', 'React'],
            projPics: [h1, h2, h3, h4, h5, h6, h7, h8,],
            git: 'https://github.com/SatendraDiwakar/Housee',
            demo: 'https://housee.netlify.app/'
        }
    ]

    const projHeaderRef = useRef();

    const { addHeaderReveal } = useAnimateOnScroll(projHeaderRef);

    // animations
    const reveal = {
        animation: 'reveal .8s ease-in-out .2s forwards'
    }

    return <section className="projects" id="projects">
        <div className="container">
            <div
                className="heading animate-top"
                ref={projHeaderRef}
                style={addHeaderReveal ? reveal : null}
            >
                <p>Projects</p>
            </div>
            <div
                className="line"
                style={addHeaderReveal ? reveal : null}
            ></div>
            <div className="project-container">
                {/* map images and links */}
                {
                    projectDetails.map(itm => {
                        return <ProjectCard
                            key={itm.id}
                            projName={itm.projName}
                            projImage={itm.projImage}
                            projDesc={itm.projDesc}
                            projTech={itm.tech}
                            projImg={itm.projPics}
                            projGit={itm.git}
                            projDemo={itm.demo}
                        />
                    })
                }

            </div>
        </div>
    </section>
}