import React, { useEffect, useRef, useState } from 'react'
// images
import logo from '../../Images/logo.png'

export default function Navbar(){

    // state
    const [size,setSize] = useState(window.innerWidth);
    // refs
    const listRef = useRef();
    const activeMenu = useRef();
    const menuToggle = useRef();

    useEffect(() => {

        let isMounted = true;

        window.addEventListener('resize', () => {

            if (isMounted === true) {
                setSize(window.innerWidth);
            }
        });

        return () => {
            isMounted = false;
        }
    }, []);

    useEffect(()=>{
        if(size<=630){
            menuToggle.current.addEventListener('click',()=>{
                activeMenu.current.classList.toggle('active');
                listRef.current.classList.toggle('open');
            });
            listRef.current.addEventListener('click',()=>{
                activeMenu.current.classList.toggle('active');
                listRef.current.classList.toggle('open');
            });
        }
    },[size]);

    return <header>
    <div className="container">
        <nav className="nav">
            <div className="logo main-logo">
                <a href="/#"><img src={logo} alt="imageLogo" /></a>
            </div>
            <div className="menu-toggle" ref={menuToggle}>
                <div className="menu" ref={activeMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div className="list">
                <ul className="list-items" ref={listRef}>
                    <li><a href="/#" className="link">Home</a></li>
                    <li><a href="/#skills" className="link">Skills</a></li>
                    <li><a href="/#projects" className="link">projects</a></li>
                    <li><a href="/#about" className="link">about</a></li>
                    <li><a href="/#contact" className="link">contact</a></li>
                </ul>
            </div>
        </nav>
    </div>
</header>
}