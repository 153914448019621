import React, { useRef, useState } from 'react'
// email js
import emailjs from 'emailjs-com'
// react icons
import { FaGithub, FaLinkedinIn, FaEnvelope } from 'react-icons/fa'
// custom hook
import { useAnimateOnScroll } from '../../Hooks/useAnimateOnScroll'
// components
import Loader2 from '../UI/Loader2'

export default function Contact() {

    // states
    const [loading, setLoading] = useState(false);
    // ref
    const aboutHeaderRef = useRef();
    const elementRef = useRef();
    // custom hook
    const { addHeaderReveal, addElementReveal } = useAnimateOnScroll(aboutHeaderRef, elementRef);

    // animations
    const reveal = {
        animation: 'reveal .8s ease-in-out .2s forwards'
    }
    const leftReveal = {
        animation: 'leftReveal 1s ease-in-out forwards'
    }
    const rightReveal = {
        animation: 'rightReveal 1s ease-in-out forwards'
    }

    // email function
    function sendEmail(e) {
        e.preventDefault();
        setLoading(true);

        emailjs.sendForm('gmail', 'template_srgeemu', e.target, `${process.env.REACT_APP_USER_ID}`)
            .then((result) => {
                setLoading(false);
                alert('Message sent to Satendra');
            }, (error) => {
                alert('Sorry can not send message, please try again later');
            });
        e.target.reset();
    }

    return <section className="contact" id="contact">
        <div className="container">
            <div
                className="heading"
                ref={aboutHeaderRef}
                style={addHeaderReveal ? reveal : null}

            >
                <p>Contact</p>
            </div>
            <div
                className="line"
                style={addHeaderReveal ? reveal : null}
            ></div>
            <div
                className="contact-content"
                ref={elementRef}
            >
                <div
                    className="con-left-part animate-left"
                    style={addElementReveal ? leftReveal : null}
                >
                    <div className="social-menu">
                        <h2 className="heads con-head">Follow Me</h2>
                        <ul className="social-icons-container">
                            <li><a
                                href="https://github.com/SatendraDiwakar"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="con-btn"
                            ><FaGithub /></a></li>
                            <li><a
                                href="https://www.linkedin.com/in/satendra-kumar-diwakar/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="con-btn"
                            ><FaLinkedinIn /></a></li>
                        </ul>
                    </div>
                </div>
                <div
                    className="con-right-part animate-right"
                    style={addElementReveal ? rightReveal : null}
                >
                    <h2 className="heads con-head">Get in Touch</h2>
                    <div className="message-container">
                        <form action="" className="msg-form" onSubmit={sendEmail}>
                            <input type="text" name="from_name" className="msg-input" placeholder="YOUR NAME" required />
                            <input type="email" name="from_email" className="msg-input" placeholder="YOUR EMAIL" required />
                            <textarea rows="4" name="from_message" className="text-area" placeholder="HOW CAN I HELP?" minLength={10} required></textarea>
                            {
                                loading ?
                                    <div className="con-loader">
                                        <Loader2 />
                                    </div>
                                    : <button type="submit" className="con-btn">
                                        <FaEnvelope className="con-btn-icon" />
                                    </button>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
}