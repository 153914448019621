import React, { useContext, useRef } from 'react'
//custom hook
import { useAnimateOnScroll } from '../../Hooks/useAnimateOnScroll'
// context
import { ModalCtx } from '../ModalCtx';

export default function ProjectCard({ projName, projImage, projDesc, projTech, projGit, projDemo, projImg }) {

    const elementRef = useRef();
    const { addElementReveal } = useAnimateOnScroll(null, elementRef);

    // context
    const { open } = useContext(ModalCtx);

    // animations
    const reveal = {
        animation: 'reveal 1s ease-in-out .5s forwards'
    }

    return <div className="card" ref={elementRef} style={addElementReveal ? reveal : null}>
        <div className="card-elements">
            <div className="card-left-part">
                <h1 className="pr-pad pr-heads heads">{projName}</h1>
                <div className="pr-thumb pr-pad">
                    <a
                        href={projDemo}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={projImage} alt="projectImage" style={projName === 'Movie Info-Ver' || 'Wiki App' ? { width: '20rem' } : { width: '16rem' }} />
                    </a>
                </div>
                <div className="used-techs">
                    {
                        projTech.map((itm,index) => {
                            return <h4 key={`${projName}itm${index}`}>{itm}</h4>
                        })
                    }
                </div>
            </div>
            <div className="card-right-part">
                <h1 className="pr-pad pr-heads heads">Description</h1>
                <p className="pr-pad pr-description">{projDesc}</p>
                <div className="btn-container pr-pad">
                    <a
                        href={projGit}
                        className="pr-pad btn btn-name"
                        target="_blank"
                        rel="noopener noreferrer"
                    ><h2>Git</h2></a>
                    <a
                        href={projDemo}
                        className="pr-pad btn btn-name"
                        target="_blank"
                        rel="noopener noreferrer"
                    ><h2>Demo</h2></a>
                    <button
                        className="pr-pad btn btn-modal"
                        onClick={() => { open(projImg) }}
                    >Pics</button>
                </div>
            </div>
        </div>
    </div>
}