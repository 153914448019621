import React, { useContext, useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
// context
import { ModalCtx } from '../ModalCtx';
// styles
import './ProjPicsModal.css';

export default function ProjPicsModal() {

    // context
    const { close, itmDetails } = useContext(ModalCtx);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (index > 0) {
            document.getElementsByClassName('btn-prevImage')[0].style = 'visibility: visible';
        } else {
            document.getElementsByClassName('btn-prevImage')[0].style = 'visibility: hidden';
        }

        if (index < (itmDetails.length - 1)) {
            document.getElementsByClassName('btn-nextImage')[0].style = 'visibility: visible';
        } else {
            document.getElementsByClassName('btn-nextImage')[0].style = 'visibility: hidden';
        }
    }, [index, itmDetails.length]);

    return (
        <div className="projPicsContainer">
            <AiOutlineClose
                className="btn-close-modal"
                onClick={close}
            />
            <FaAngleLeft
                className="btn-modal-action btn-prevImage"
                onClick={() => { setIndex(prev => prev - 1) }}
            />
            <img src={itmDetails[index]} alt="projImage" className="modalImg" />
            <FaAngleRight
                className="btn-modal-action btn-nextImage"
                onClick={() => { setIndex(prev => prev + 1) }}
            />
        </div>
    )
}
