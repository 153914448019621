import { useContext, useState } from 'react';
import './App.css';
// context
import { ModalCtx } from './Components/ModalCtx';
// image
import heroBack from './Images/hero.jpg'
// components
import Portal from './Components/HOC/Portal';
import ProjPicsModal from './Components/ProjPicsModal';
import Loader from './Components/UI/Loader/Loader';
import Navbar from './Components/Navbar/Navbar';
import Hero from './Components/Hero/Hero'
import Skills from './Components/Skills/Skills';
import Projects from './Components/Projects/Projects';
import About from './Components/About/About'
import Contact from './Components/Contact/Contact';
import Footer from './Components/Footer/Footer'

function App() {

  let [sec, setSec] = useState(0);
  // context
  const { isOpen } = useContext(ModalCtx);

  return <>
    {
      !sec && <Loader />
    }
    <Portal open={isOpen}>
      <ProjPicsModal />
    </Portal>
    <div className="backgroundHero">
      <img src={heroBack} onLoad={() => {
        setTimeout(() => {
          setSec(1)
        }, 2000);
      }} alt="" />
    </div>
    {
      sec &&
      <>
        <Navbar />
        <Hero />
        <Skills />
        <Projects />
        <About />
        <Contact />
        <Footer />
      </>
    }
  </>
}

export default App;
