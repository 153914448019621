import React, { useRef } from 'react'
// custom hook
import { useAnimateOnScroll } from '../../Hooks/useAnimateOnScroll'
// images
import htmlLogo from '../../Images/skillLogo/html-logo.png'
import cssLogo from '../../Images/skillLogo/css-logo.png'
import jsLogo from '../../Images/skillLogo/js-logo.png'
import nextJs from '../../Images/skillLogo/nextjs-logo.png'
import reactLogo from '../../Images/skillLogo/react-logo.png'

export default function Skills() {

    //srefs
    const skillsHeaderRef = useRef();
    const elementRef = useRef();
    // custom hook
    const { addHeaderReveal, addElementReveal } = useAnimateOnScroll(skillsHeaderRef, elementRef);

    // animations
    const reveal = {
        animation: 'reveal .8s ease-in-out .2s forwards'
    }
    const s1 = {
        animation: 'reveal .8s ease-in-out .5s forwards'
    }
    const s2 = {
        animation: 'reveal .8s ease-in-out .7s forwards'
    }
    const s3 = {
        animation: 'reveal .8s ease-in-out .9s forwards'
    }
    const s4 = {
        animation: 'reveal .8s ease-in-out 1.1s forwards'
    }
    const s5 = {
        animation: 'reveal .8s ease-in-out 1.3s forwards'
    }

    return <section id="skills" className="skills">
        <div className="container">
            <div
                className="heading color-dark"
                ref={skillsHeaderRef}
                style={addHeaderReveal ? reveal : null}
            >
                <p>Skills</p>
            </div>
            <div
                className="line dark-line"
                style={addHeaderReveal ? reveal : null}
            ></div>
            <div className="skills-name-and-logo ">
                <h2
                    className="skill-container animate-s1"
                    style={addElementReveal ? s1 : null}
                    ref={elementRef}
                >
                    <div className="logr">
                        <img src={htmlLogo} alt="html-logo" className="logo skill-logo" style={{width: '6rem'}} />
                    </div>
                    HTML
                </h2>
                <h2
                    className="skill-container animate-s2"
                    style={addElementReveal ? s2 : null}
                    ref={elementRef}
                >
                    <div className="logr">
                        <img src={cssLogo} alt="css-logo" className="logo skill-logo" style={{width: '6rem'}} />
                    </div>
                    CSS
                </h2>
                <h2
                    className="skill-container animate-s3"
                    style={addElementReveal ? s3 : null}
                    ref={elementRef}
                >
                    <div className="logr">
                        <img src={jsLogo} alt="js-logo" className="logo skill-logo" style={{width: '6rem'}} />
                    </div>
                    JAVASCRIPT
                </h2>
                <h2
                    className="skill-container animate-s4"
                    style={addElementReveal ? s4 : null}
                    ref={elementRef}
                >
                    <div className="logr">
                        <img src={nextJs} alt="nextjs-logo" className="logo skill-logo" style={{width: '8rem'}} />
                    </div>
                    NextJs
                </h2>
                <h2
                    className="skill-container animate-s5"
                    style={addElementReveal ? s5 : null}
                    ref={elementRef}
                >
                    <div className="logr">
                        <img src={reactLogo} alt="react-logo" className="logo skill-logo" />
                    </div>
                    REACT
                </h2>
            </div>
        </div>
    </section>
}