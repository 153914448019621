import React from 'react';
// react icons
import { IoArrowDownCircle } from 'react-icons/all'

export default function Hero() {
    return <section className="hero" id="hero">
        <div className="container">
            <div className="greet">
                <p className="mov-down">Hello There</p>
                <p className="fade">I'm</p>
            </div>
            <div>
                <p className="name fade">Satendra Kumar Diwakar</p>
            </div>
            <div className="small-about mov-up">
                <p>A front-end developer , Who loves Coding.</p>
            </div>
            <div className="scrollDownArrowContainer">
                <IoArrowDownCircle className="scrollDownArrow" />
                <p className="scrollDownMsg">Scroll Down</p>
            </div>
        </div>
    </section>
}