import { useLayoutEffect, useEffect, useRef, useState } from "react";

export const useAnimateOnScroll = (headerRef, elementRef) => {

    // state
    const [addElementReveal, setAddElementReveal] = useState(false); // state for conditional animation
    const [addHeaderReveal, setAddHeaderReveal] = useState(false); // state for conditional animation

    // component mount
    const componentMount = useRef(false);
    useEffect(() => {
        return () => {
            componentMount.current = true;
        }
    }, [])

    // logic to hide/reveal thumbs on scroll 
    useLayoutEffect(() => {

        // setting addReveal state when user scrolls up & down
        function scrollUpDown() {
            
            if (headerRef) {
                // checking if header are in the view of screen or not
                if (headerRef.current.getBoundingClientRect().top < window.innerHeight) {
                    if (headerRef.current.getBoundingClientRect().bottom > 0)
                        setAddHeaderReveal(true)
                    // else
                        // setAddHeaderReveal(false)
                } else {
                    // setAddHeaderReveal(false)
                }
            }

            if (elementRef) {
                // checking if element are in the view of screen or not
                if (elementRef.current.getBoundingClientRect().top < window.innerHeight) {
                    if (elementRef.current.getBoundingClientRect().bottom > 0)
                        setAddElementReveal(true)
                    // else
                        // setAddElementReveal(false)
                } else {
                    // setAddElementReveal(false)
                }
            }
        } // By commenting else part we're saying that scroll only once.

        // adding scroll event listener
        window.addEventListener('scroll', scrollUpDown)

        // clean up
        return () => {
            if (componentMount) {
                window.removeEventListener('scroll', scrollUpDown)
            }
        }
    });

    return { addHeaderReveal, addElementReveal }
}